<template>
<div>
  <RegistrationMasukanOtp></RegistrationMasukanOtp>
</div>
</template>

<script>

import RegistrationMasukanOtp from "@/components/registration/UserRegistrationMasukanOtp.vue";

export default {
  name: "RegistrationMasukanOtpView",
  components: {RegistrationMasukanOtp},
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style scoped>

</style>
